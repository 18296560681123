import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader, Menu, Breadcrumb, Segment, Icon, Dropdown, Button } from 'semantic-ui-react';

import Modal from '../../components/Modal';
import Responsive from '../../components/Responsive';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';
import SortableList from '../../components/SortableList';
import FieldReport from '../../components/ui/FieldReport';
import * as utils from '../../utils';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query ReportNodeQuery ($id: ID!) {
  node (id: $id) {
    ... on Report {
      id: dbId
      name
      description
      fields (limit: 400) {
        nodes {
          id: dbId
          name
          type
          initial
          options
          position
        }
      }
      groups (limit: 400) {
        nodes {
          id: dbId
        }
      }
    }
  }
  }
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    variables: {
      id: ownProps.params.report_id
    }
  })
})
@graphql(gql`mutation updateReport($updateReportMutation: UpdateReportInput!) {
  updateReport(input: $updateReportMutation) {
    clientMutationId
  }
}`, {
  name: 'updateReport',
  options: {
    refetchQueries: ['ReportResultFormQuery', 'ReportNodeQuery']
  }
})
@graphql(gql`mutation createReportField($createReportFieldMutation: CreateReportFieldInput!) {
  createReportField(input: $createReportFieldMutation) {
    clientMutationId
  }
}`, {
  name: 'createReportField',
  options: {
    refetchQueries: ['ReportResultFormQuery', 'ReportNodeQuery']
  }
})
@graphql(gql`mutation updateReportField($updateReportFieldMutation: UpdateReportFieldInput!) {
  updateReportField(input: $updateReportFieldMutation) {
    clientMutationId
  }
}`, {
  name: 'updateReportField',
  options: {
    refetchQueries: ['ReportResultFormQuery', 'ReportNodeQuery']
  }
})
@observer
export default class ReportNode extends Responsive {
  state = {
    sorted: null
  }

  onSort = data => this.setState({ sorted: data })

  onUpdateReport = () => {
    const { sorted: data } = this.state;
    const values = { ...this.props.data.node };

    values.fields = data.items.map(item => item.field);

    values.fields = values.fields.map((f, i) => {
      const field = { ...f };

      if (field.type === 'SELECT' && field.initial && field.initial.length) {
        field.initial = parseInt(field.initial[0], 10);
      } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
        field.initial = field.initial.map(option => parseInt(option, 10));
      } else if (field.type === 'CHECK') {
        field.initial = [];
      }

      field.position = i + 1;

      delete field.__typename;

      return { ...field };
    });

    values.groups = values.groups.nodes.map(group => ({ id: group.id }));

    delete values.__typename;

    this.props.updateReport({
      variables: {
        updateReportMutation: {
          ...values,
          attach: 'false'
        }
      }
    }).then(() => this.setState({ sorted: null }));
  }

  onReportFieldDelete = (id) => {
    const { router, params } = this.props;
    const { organization_id, report_id } = params;

    router.push(`/organizations/${organization_id}/reports/${report_id}/fields/${id}/delete`);
  }

  onReportFieldDuplicate = (field) => {
    const values = {};

    values.name = __('Copy of ') + field.name;
    values.type = field.type;
    values.initial = field.initial;
    values.options = field.options;
    values.position = this.props.data.node.fields.nodes.length + 1;

    values.initial = utils.formatReportFieldInitial({
      type: field.type,
      initial: field.initial,
    });

    this.props.createReportField({
      variables: {
        createReportFieldMutation: {
          reportId: this.props.params.report_id,
          ...values
        }
      }
    });
  }

  onReportFieldSubmit = i => (value) => {
    this.props.store.appends.pop();

    value.initial = utils.formatReportFieldInitial({
      type: value.type,
      initial: value.initial,
    });

    if (i === null) {
      this.props.createReportField({
        variables: {
          createReportFieldMutation: {
            reportId: this.props.params.report_id,
            ...value,
            position: this.props.data.node.fields.nodes.length + 1
          }
        }
      });
    } else {
      this.props.updateReportField({
        variables: {
          updateReportFieldMutation: {
            ...value
          }
        }
      });
    }
  }

  openReportFieldForm = (i = null) => {
    const field = i !== null ? { ...this.props.data.node.fields.nodes[i] } : null;
    const title = (i !== null) ? __('Edit field') : __('Add field');

    if (field) {
      if (field.type === 'SELECT' && field.initial && field.initial.length) {
        field.initial = parseInt(field.initial[0], 10);
      } else if (field.type === 'CHECK' && field.initial && field.initial.length) {
        field.initial = field.initial.map(option => parseInt(option, 10));
      } else if (field.type === 'CHECK') {
        field.initial = [];
      } else if (field.type === 'TEXT') {
        field.options = [''];
      }

      delete field.__typename;
    }

    this.props.store.appends.push(
      <BetaModal
        id="ReportFieldAdd"
        size="small"
        closeOnRootNodeClick={false}
        fullScreen={this.isMobile()}
        portalHeader={this.isMobile()}
        // fixHeader={this.isMobile()}
        actions={false}
        header={this.isMobile() ? null : title}
        content={<FieldReport
          modal
          values={field}
          onSubmit={this.onReportFieldSubmit(i)}
          submitButton={{
            text: i !== null ? __('Save') : __('Add'),
            isActionButtom: true
          }}
          cancelButton={{
            text: __('Cancel'),
            isActionButtom: true
          }}
          title={title}
        />}
      />
    );
  }

  renderField = (field, sortable, i) => <Segment
    key={i}
    className={'horizontallySpacedItems' + (sortable ? ' buttonHover pointer wordWrapped' : '')}
    style={{ alignItems: 'center', margin: '8px 0px', borderColor: '#eeeeee', minHeight: '62px', flexWrap: 'nowrap' }}
    onClick={() => (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) && this.openReportFieldForm(i)}
  >
    <div>
      {sortable && <Icon name="sort" style={{ color: '#a0a0a0', fontSize: '12px', cursor: 'move' }} />}
      <span className="bold">
        {field.name}
      </span>
    </div>
    {
      (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
      (
        <Dropdown icon={null} trigger={<Icon name="ellipsis v" style={{ color: '#a0a0a0', fontSize: '16px' }} />}>
          <Dropdown.Menu style={this.isMobile() ? { left: 'unset', right: 0 } : {}}>
            {
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) &&
              (
                <Dropdown.Item onClick={() => this.onReportFieldDuplicate(field)}>
                  <Icon name="clone" />{__('Duplicate')}
                </Dropdown.Item>
              )
            }
            {
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
              (
                <Dropdown.Item onClick={() => this.onReportFieldDelete(field.id)}>
                  <Icon name="trash" />{__('Delete field')}
                </Dropdown.Item>
              )
            }
          </Dropdown.Menu>
        </Dropdown>
      )
    }
  </Segment>

  renderSortableFields = fields => <SortableList
    data={{
      items: fields.map((field, i) => ({ content: this.renderField(field, true, i), field }))
    }}
    onSort={this.onSort}
    style={{ marginTop: '20px' }}
  />

  renderFields = (fields, sortable) => (sortable ? this.renderSortableFields(fields) :
    (
      <div style={{ marginTop: '20px' }}>
        {fields.map((field, i) => <div key={i} outline="list">{this.renderField(field, false, i)}</div>)}
      </div>
    ))

  render() {
    const { data, params, store } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active inline="centered" />;

    const { name, fields: { nodes } } = data.node;

    return (
      <div id="ReportNode">
        {
          this.state.sorted ?
            <Menu compact floated="right" style={{ marginTop: '-1em' }}>
              <Menu.Item
                className="bold"
                data-action="update"
                content={__('Save Changes')}
                onClick={() => this.onUpdateReport()}
              />
            </Menu>
            :
            [
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT') || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
              (
                <Menu compact floated="right" style={{ marginTop: '-1em' }}>
                  <Menu.Item className="bold" style={{ padding: '12px' }}>
                    <Dropdown icon={null} trigger={<Icon name="ellipsis h" style={{ margin: 0 }} />}>
                      <Dropdown.Menu style={{ left: 'unset', right: 0 }}>
                        {
                          (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) &&
                          (
                            <Dropdown.Item as={Link} to={`/organizations/${params.organization_id}/reports/${params.report_id}/edit`}>
                              <Icon name="pencil" />{__('Edit report')}
                            </Dropdown.Item>
                          )
                        }
                        {
                          (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('DELETE_REPORT')))) &&
                          (
                            <Dropdown.Item as={Link} to={`/organizations/${params.organization_id}/reports/${params.report_id}/delete`}>
                              <Icon name="trash" />{__('Delete report')}
                            </Dropdown.Item>
                          )
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
                </Menu>
              ),
              (this.props.store.currentEntity.type === 'ADMIN' || (this.props.store.currentEntity.organization && this.props.store.currentEntity.organization.permissions && (this.props.store.currentEntity.organization.permissions.hasAdmin || this.props.store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT')))) &&
              <Menu compact floated="right" style={{ marginTop: '-1em' }}>
                <Menu.Item
                  className="bold"
                  data-action="add-field"
                  content={__('New Field')}
                  icon="plus"
                  onClick={() => this.openReportFieldForm()}
                />
              </Menu>
            ]
        }
        <Breadcrumb>
          <Breadcrumb.Section
            link
            className="bold defaultClspColor"
            as={Link}
            to={`/organizations/${params.organization_id}/reports`}
          >
            {__('Reports')}
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section active>{(name.length > 55) ? (name.substring(0, 55) + '...') : name}</Breadcrumb.Section>
        </Breadcrumb>
        {
          data.loading ?
            <Loader active />
            :
            nodes.length ?
              this.renderFields(nodes, store.currentEntity.type === 'ADMIN' || (store.currentEntity.organization && store.currentEntity.organization.permissions && (store.currentEntity.organization.permissions.hasAdmin || store.currentEntity.organization.permissions.entityScopes.includes('CREATE_UPDATE_REPORT'))))
              :
              <Segment textAlign="center" style={{ margin: '28px 0px 8px 0px' }}>
                {__('This report does not have fields yet. Click "New Field" to create the first field.')}
              </Segment>
        }
        {this.props.children}
      </div>
    );
  }
}
