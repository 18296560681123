import React from 'react';
import { inject, observer } from 'mobx-react';
import { Accordion, Icon, Dropdown } from 'semantic-ui-react';

import { __ } from '../../i18n';

import Form from '../../components/Form';
import ColoredCheckbox from '../../components/ColoredCheckbox';

const styles = {
  label: {
    margin: '0px 0px 8px 0px'
  }
};

@inject('store') @observer
export default class ReportForm extends Form {

  defaultValues = {
    name: '',
    description: '',
    groups: [],
    privacy: false
  }

  rules = {
    name: ['required', values => this.validateContent('name', values)],
    description: [values => this.validateContent('description', values)]
  }

  validateContent = (name, values) => {
    if (name === 'name' && values.trim().length <= 0) throw new Error(__('Report name cannot be empty'));

    if (name === 'name' && values.trim().length > 255) throw new Error(__('Report name cannot be longer than 255 characters'));

    if (name === 'description' && ((this.state.values.description.length > 64))) throw new Error(__('Report description cannot be longer than 64 characters'));
  }

  beforeSubmit = (values) => {
    if (!values.privacy) {
      values.groups = [];
    } else {
      values.groups = values.groups.map(id => ({ id }));
    }

    delete values.privacy;

    return values;
  }

  getGroups = () => this.props.organization.groups.nodes.map((group, key) =>
    ({ text: (group.name.length > 50) ? (group.name.substring(0, 50) + '...') : group.name, value: group.id, key, 'data-id': group.id }))

  render() {
    const { values, errors, moreOptions } = this.state;

    return (
      <Form id="ReportForm" {...this.props} onSubmit={this.handleSubmit}>
        <p className="bold" style={styles.label}>{__('Report name')}</p>
        <Form.Input
          placeholder={__('Report name')}
          name="name"
          value={values.name}
          onChange={this.onInputChange}
          autoFocus
        />
        {
          errors && errors.name ?
            <div style={{ marginBottom: 14 }}>
              <Icon data-value="error" name="exclamation circle" size="large" color="red" />
              {errors.name}
            </div>
            :
            null
        }
        <Accordion>
          <Accordion.Title className="bold" style={{ fontSize: '12px' }} active={moreOptions} onClick={() => this.setState({ moreOptions: !moreOptions })}>
            <Icon name="dropdown" />
            {__('More options').toUpperCase()}
          </Accordion.Title>
          <Accordion.Content active={moreOptions}>
            <p className="bold" style={styles.label}>{__('Description (optional)')}</p>
            <Form.Input
              placeholder={__('Ex: Daily Routine')}
              name="description"
              value={values.description}
              onChange={this.onInputChange}
            />
            {
              errors && errors.description ?
                <div style={{ marginBottom: 14 }}>
                  <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                  {errors.description}
                </div>
                :
                null
            }
            {
              this.props.organization &&
              [
                <p className="bold" style={{ margin: '6px 0px 8px 0px' }}>{__('Privacy')}</p>,
                <div>
                  <ColoredCheckbox
                    radio
                    checked={!values.privacy}
                    label={__('Allow access to all staffs of the Institution (default)')}
                    onClick={() => this.onInputChange(null, { name: 'privacy', checked: false })}
                  />
                </div>,
                <div>
                  <ColoredCheckbox
                    radio
                    checked={values.privacy}
                    label={__('Allow access to one or more groups of the Institution')}
                    onClick={() => this.onInputChange(null, { name: 'privacy', checked: true })}
                  />
                </div>,
                values.privacy ?
                  <div style={{ margin: '12px 0px 0px 24px' }}>
                    <Dropdown
                      className={'menu-max-height'}
                      upward={this.isMobile()}
                      ref={(c) => { this.groupsDropdown = c; }}
                      placeholder={__('Search') + '...'}
                      name="groups"
                      noResultsMessage={__('No results were found')}
                      fluid
                      search
                      multiple
                      selection
                      value={values.groups}
                      options={this.getGroups()}
                      onChange={(e, data) => this.onSelectionChange(e, data, () => this.groupsDropdown.clearSearchQuery())}
                    />
                  </div>
                  :
                  null
              ]
            }
          </Accordion.Content>
        </Accordion>
      </Form>
    );
  }
}
