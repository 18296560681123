import React from 'react';
import { inject, observer } from 'mobx-react';
import { Container, Card, Icon, Dropdown, Popup } from 'semantic-ui-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import Responsive from '../../components/Responsive';
import OptionsToast from '../../components/OptionsToast';
import Modal from '../../components/ui/Modal';

@inject('store') @observer
export default class ReportItem extends Responsive {
  renderMenu = () => {
    const menu = [
      { dataAction: 'delete', text: __('Delete report'), icon: 'trash', color: '#BF2600', closeOnClick: true, onClick: () => { this.props.onDelete(); } },
      { dataAction: 'cancel', text: __('Cancel'), icon: 'times', color: '#000000', closeOnClick: true },
    ];
    return (
      this.props.store.appends.push(<Modal
        id="OptionsToast"
        toast
        invertCloseButton
        onClose={() => this.props.store.appends.pop()}
        closeOnRootNodeClick
        header={__('Report Options')}
        spaceFooter
        content={<OptionsToast menu={menu} />}
      />));
  }

  render() {
    const { report, id, isMessageToApprove } = this.props;
    const { name } = report;

    const items = [
      <Dropdown.Item onClick={() => this.props.onDelete()}>{__('Delete')}</Dropdown.Item>
    ];

    if (!isMessageToApprove) items.unshift(<Dropdown.Item onClick={() => this.props.onEdit()}>{__('Edit')}</Dropdown.Item>);
    return (
      <Card
        data-type="ReportItem"
        data-index={id}
        fluid
        style={{
          marginBottom: '1em',
          width: this.isMobile() ? '100%' : '355px',
          height: this.isMobile() ? '' : '159px',
          borderRadius: '12px',
          marginRight: '8px'
        }}
      >
        <Card.Content style={{ margin: 0 }}>
          <Container textAlign="center">
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? '22px 10px 14px' : '22px 24px 14px' }}>
              <div className="bold"><Icon name="list layout" style={{ color: '#000000', marginRight: '12px', fontSize: '16px', fontWeight: 300 }} />
                <Popup
                  trigger={<span>{(name.length > 25) ? (name.substring(0, 25) + '...') : name}</span>}
                  content={<span>{name}</span>}
                  hideOnScroll
                />
              </div>
              {
                (items.length > 0) ?
                  !this.isMobile() ?
                    <Dropdown data-testid="dropdown-icon" icon={null} trigger={<Icon name="ellipsis v" style={{ margin: 0 }} />}>
                      <Dropdown.Menu className="bold">
                        {items}
                      </Dropdown.Menu>
                    </Dropdown>
                    :
                    <Icon name="ellipsis v" onClick={() => this.renderMenu()} />
                  :
                  null
              }
            </div>
            <div id="ReportItemDetails" style={{ margin: this.isMobile() ? '0 18px 24px' : '0 32px 24px' }}>
              <div
                style={{
                  borderRadius: '200px',
                  backgroundColor: '#ffffff',
                  display: 'table',
                  marginBottom: '5px',
                  width: '100%',
                  border: '1px solid #084FFF99'
                }}
              >
                <p style={{ fontSize: '13px', fontWeight: '700', padding: '12px', color: '#084FFF', display: 'table-cell', verticalAlign: 'middle' }}>
                  {
                    report.multiple ?
                      <div style={{ display: 'grid', fontWeight: 'bold' }}>
                        <span>{__('Specific to Each Person,')}</span>
                        <span>{((report && report.recipients && report.recipients.length > 0 ? report.recipients.length : report.entities.length)) + __(' recipients')}</span>
                      </div>
                      :
                      <span style={{ fontWeight: 'bold' }}>{__('Same for Everyone')}</span>
                  }
                </p>
              </div>
            </div>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}
