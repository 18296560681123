import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import ReportForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query ReportAddQuery ($id: ID!) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        groups (limit: 400) {
          nodes {
            name
            id: dbId
          }
        }
      }
    }
  }
`, {
  options: ownProps => ({
    variables: {
      id: ownProps.params.organization_id
    }
  }),
  skip: ownProps => ownProps.store.currentEntity !== 'ADMIN' && !(ownProps.store.currentEntity.organization && ownProps.store.currentEntity.organization.permissions && (ownProps.store.currentEntity.organization.permissions.hasAdmin || ownProps.store.currentEntity.organization.permissions.entityScopes.includes('READ_GROUP')))
})
@graphql(gql`mutation createReport($createReportMutation: CreateReportInput!) {
  createReport(input: $createReportMutation) {
    report {
      id: dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['ReportResultFormQuery']
  }
})
@observer
export default class ReportAdd extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Add report');
  }

  request = (values) => {
    const { store, router } = this.props;
    return this.props.mutate({
      variables: {
        createReportMutation: { organizationId: store.currentOrganization.id, ...values }
      }
    }).then((data) => {
      const { report } = data.data.createReport;
      router.push(`/organizations/${store.currentOrganization.id}/reports/${report.id}`);
    });
  }

  render() {
    const { data } = this.props;

    if (data && ((data.loading && !data.node) || !data.node)) return <Modal loading />;

    return (
      <Controller
        id="ReportAdd"
        modal={{ size: 'small' }}
        add
        title={__('Add report')}
        form={ReportForm}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        formProps={data ? { organization: data.node } : {}}
        {...this.props}
      />
    );
  }
}
