import { inject, observer } from 'mobx-react';
import React from 'react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import Modal from '../../components/Modal';

import ReportForm from './Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`mutation updateReport($updateReportMutation: UpdateReportInput!) {
  updateReport(input: $updateReportMutation) {
    report {
      dbId
    }
    clientMutationId
  }
}`, {
  options: {
  refetchQueries: ['ReportEditQuery', 'ReportResultFormQuery', 'ReportNodeQuery']
  }
  })
@graphql(gql`query ReportEditQuery($organizationId: ID!, $reportId: ID!) {
  organization: node(id: $organizationId) @connection(key: "Organization", filter: ["organizationId"]) {
    ... on Organization {
      id: dbId
      groups (limit: 400) {
        nodes {
          name
          id: dbId
        }
      }
    }
  }
  report: node (id: $reportId) {
    ... on Report {
      id: dbId
      name
      description
      groups (limit: 400) {
        nodes {
          id: dbId
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
    organizationId: ownProps.params.organization_id,
    reportId: ownProps.params.report_id
    }
    }),
  skip: ownProps => ownProps.store.currentEntity !== 'ADMIN' && !(ownProps.store.currentEntity.organization && ownProps.store.currentEntity.organization.permissions && (ownProps.store.currentEntity.organization.permissions.hasAdmin || ownProps.store.currentEntity.organization.permissions.entityScopes.includes('READ_GROUP')))
  })
@graphql(gql`query ReportEditQuery($organizationId: ID!, $reportId: ID!) {
  report: node (id: $reportId) {
    ... on Report {
      id: dbId
      name
      description
      groups (limit: 400) {
        nodes {
          id: dbId
        }
      }
    }
  }
}`, {
  options: ownProps => ({
    variables: {
    organizationId: ownProps.params.organization_id,
    reportId: ownProps.params.report_id
    }
    }),
  skip: ownProps => ownProps.store.currentEntity === 'ADMIN' || (ownProps.store.currentEntity.organization && ownProps.store.currentEntity.organization.permissions && (ownProps.store.currentEntity.organization.permissions.hasAdmin || ownProps.store.currentEntity.organization.permissions.entityScopes.includes('READ_GROUP')))
  })
@observer
export default class ReportEdit extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Edit report');
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.values && nextProps.data.report) {
      const values = { ...nextProps.data.report };

      values.groups = values.groups.nodes.map(group => group.id);
      values.privacy = !!values.groups.length;

      delete values.__typename;

      this.setState({ values });
    }
  }

  request = (values) => {
    const { store, router, params } = this.props;

    return this.props.mutate({
      variables: {
        updateReportMutation: {
          ...values,
          attach: 'false'
        }
      }
    })
      .then(() => {
        store.snackbar = { active: true, message: __('%s was saved', values.name), success: true };
        router.goBack();
      });
  }

  render() {
    const { data } = this.props;

    if ((data.loading && !data.report) || !data.report) return <Modal loading />;

    return (
      <Controller
        id="ReportEdit"
        modal
        edit
        title={__('Edit report')}
        form={ReportForm}
        values={this.state.values}
        loading={this.props.data.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        formProps={data.organization ? { organization: data.organization } : {}}
        {...this.props}
      />
    );
  }
}
