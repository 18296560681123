import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import Controller from '../../components/Controller';
import { __ } from '../../i18n';

@graphql(gql`mutation deleteReport($deleteReportMutation: DeleteReportInput!) {
  deleteReport(input: $deleteReportMutation) {
    report {
      dbId
    }
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['ReportResultFormQuery']
  }
})
@inject('store') @observer
export default class ReportDelete extends Controller {
  request = () => this.props.mutate({
    variables: {
      deleteReportMutation: {
        id: this.props.params.report_id
      }
    }
  }).then(() => {
    this.props.store.snackbar = { active: true, message: __('Report was deleted'), success: true };
    this.props.router.push(`/organizations/${this.props.params.organization_id}/reports`);
  })

  render() {
    return (
      <Controller
        id="ReportDelete"
        delete
        object={__('report')}
        onSubmit={this.onSubmit}
        {...this.props}
      />
    );
  }
}
